// bot动作配置
import capi from '../capi/instance';

import { CommonRes, PageType } from '../../types/index';
import {
  ActionListItem,
  ActionDetail,
  ActionGroupSpreadSave,
  QuestionItem,
  ReplyItem,
  DomainItem,
  TtsWordDescribe,
  QaCateItem,
  DocItem,
  PageNotifyItem,
} from '../../types/sentence';

import * as utils from '../../pages/sentence_list/utils';
import { PolyphonicMap } from 'utils/polyphonic';
// 配置列表 Req
interface ActionListReq extends PageType {
  filter: {
    type?: string;
    question?: string;
    questionId?: string;
    reply?: string;
    scene?: string;
    domainName?: string;
    intentionName?: string;
    slot?: number;
    putWay?: number;
  };
  cgiHeader?: any;
}
// 配置列表 Res
interface ActionListRes extends CommonRes, PageType {
  records: ActionListItem[];
}

// 配置详情查询 Req
interface ActionDetailReq {
  actionConfigId: number;
  virtualmanKey?: string;
  cgiHeader?: any;
}
// 配置详情查询 Res
// type ActionDetailRes = CommonRes & ActionDetail;
interface ActionDetailRes extends CommonRes, ActionDetail {}

// 保存配置(新增or更新) Req
export type SaveActionReq = Omit<ActionDetail, 'actionGroupsSpread'> & {
  actionGroupsSpread: ActionGroupSpreadSave[];
  checkExist?: boolean;
  cgiHeader?: any;
};

// 删除配置 Req
interface RemoveActionReq {
  actionConfigIds: number[];
  cgiHeader?: any;
}

// 查询问题列表 Req
interface ActionQuestionListReq extends PageType {
  filter: {
    source: string;
    content: string;
    dataSource: string;
  };
}
// 查询问题列表 Res
interface ActionQuestionListRes extends CommonRes, PageType {
  records: QuestionItem[];
}

// 查询回复语列表 Req
interface ActionReplyListReq extends PageType {
  filter: {
    source: string;
    content: string;
    dataSource: string;
  };
}
// 查询回复语列表 Res
interface ActionReplyListRes extends CommonRes, PageType {
  records: ReplyItem[];
}

// 查询领域意图列表 Req
type ActionDomainListReq = PageType;

// 查询领域意图列表 Res
interface ActionDomainListRes extends CommonRes, PageType {
  records: DomainItem[];
}

// 话术试听 Req
interface GetPinYinReq {
  pinyinText: string;
}

// 话术试听 Res
interface GetPinYinRes extends CommonRes {
  pinyinInfo: {
    characterText: string;
    pinyinNumbers: string[];
    pinyinDefaults: string[];
    pinyinRecommendNumbers: {
      sequence: number;
      recommendInfo: string;
    }[];
    pinyinRecommendDefaults: {
      sequence: number;
      recommendInfo: string;
    }[];
  }[];
}

// 播报动作预测根据文本内容查询 Res
interface DescribeAnalyzerByTextReq {
  analyzerText: string;
  anchorCode?: string;
  speed: number;
}

// 播报动作预测根据文本内容查询 Req
interface DescribeAnalyzerByTextRes extends CommonRes {
  analyzerResult: string;
}
// 自动动作配置 Req
interface MatchActionReq {
  actionConfigIds?: number[];
  allActionConfigId?: boolean;
}

interface MatchActionRes extends CommonRes {
  actionAnalyzerStatus?: number;
}

// 重制动作配置
interface ResetActionReq {
  actionConfigIds?: number[];
  allActionConfigId?: boolean;
}

// 导出回复语Req
interface ExportReplyReq {
  actionConfigIds?: number[];
  allActionConfigId?: boolean;
}

// 导出回复语Res
interface ExportReplyRes extends CommonRes {
  downloadUrl: string;
}

// 导入回复语req
interface ImportReplyReq {
  importUrl: string;
  onlyCheck: boolean;
  type?: string;
  cgiHeader?: any;
}

// 导入回复语res
interface ImportReplyRes extends CommonRes {
  importInfo: string;
}

// tts耗时数据查询res
interface GetTtsDescribeReq {
  sentence: string;
  timbreCode: string;
  speedCode: string;
}

// tts耗时数据查询res
interface GetTtsDescribeRes extends CommonRes {
  subtitles: TtsWordDescribe[];
}
// 查询问答分类req
interface GetListQACateReq {
  botBizId: string;
}

// 查询问答分类res
interface GetListQACateRes extends CommonRes {
  list: QaCateItem[];
}

// 查询文档req
interface GetListSelectDocReq {
  botBizId: string;
}

// 查询文档res
interface GetListSelectDocRes extends CommonRes {
  list: DocItem[];
  total: number;
}

// 查询发布信息req
interface GetListNotifyReq {
  botBizId: string;
  cursor: string;
  lastId: string;
  pageId: string;
}

// 查询发布信息res
interface GetListNotifyRes extends CommonRes {
  centerNotifyList: [];
  cursorNotifyList: [];
  hasUnconfirmedQa: boolean;
  pageNotifyList: PageNotifyItem[];
  unconfirmedQaNum: string;
  unreadNum: string;
}
// 查询发布状态req
interface GetReleaseInfoReq {
  botBizId: string;
}

// 用户权限信息res
interface GetReleaseInfoRes extends CommonRes {
  isUpdated: boolean;
  status: number;
}

// 用户权限信息req
interface GetUserAccountReq {
  botBizId: string;
}

// 查询发布状态res
interface GetUserAccountRes extends CommonRes {
  isGenQa: boolean;
  nickName: string;
  permissionList: {
    permissionId: string;
    permissionName: string;
  }[];
  staffBizId: string;
}

// 配置列表
export const getActionList = async (data: ActionListReq) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<ActionListReq, ActionListRes>('DescribeActionConfigList', utils.fixPageReq(data));
  return utils.fixPageRes(res);
};

// 大模型 同步问答对
export const synchronizedQA = async (data: { botBizId: string; cgiHeader?: any }) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<any, any>('SynchronizedQA', data);
  return res;
};

// 大模型 查询问答对列表接口
export const describeListQA = async (data: { botBizId: string; queryQuestion: string; cgiHeader?: any }) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<any, any>('DescribeListQA', data);
  return res;
};

// 大模型 文本预处理接口
export const describePreprocess = async (data: { textType: number; text: string }) => {
  const res = await capi<any, any>('DescribePreprocess', data);
  return res;
};

// 配置详情查询
export const getActionDetail = async (data: ActionDetailReq) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<ActionDetailReq, ActionDetailRes>('DescribeActionConfigDetail', data);
  return res;
};

// 删除配置
export const removeAction = async (data: RemoveActionReq) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<RemoveActionReq, CommonRes>('DeleteActionConfig', data);
  return res;
};

// 保存配置(新增or更新)
export const saveAction = async (data: SaveActionReq, noAutoHandle: boolean) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<SaveActionReq, CommonRes>('SaveActionConfig', data, !!noAutoHandle);
  return res;
};

// 查询问题列表
export const getActionQuestionList = async (data: ActionQuestionListReq) => {
  const res = await capi<ActionQuestionListReq, ActionQuestionListRes>('QueryQuestions', utils.fixPageReq(data));
  return utils.fixPageRes(res);
};

// 查询回复语列表
export const getActionReplyList = async (data: ActionReplyListReq) => {
  const res = await capi<ActionReplyListReq, ActionReplyListRes>('QueryRepliesList', utils.fixPageReq(data));
  return utils.fixPageRes(res);
};

// 查询领域意图列表
export const getActionDomainList = async (data: ActionDomainListReq) => {
  const res = await capi<ActionDomainListReq, ActionDomainListRes>('QueryDomains', utils.fixPageReq(data));
  return utils.fixPageRes(res);
};

// 	多音字查询
export const getPinYin = async (data: GetPinYinReq) => {
  const res = await capi<GetPinYinReq, GetPinYinRes>('DescribePinyin', data);
  const pinyinMap: {
    [key: string]: {
      pinyinsDefault: string[];
      pinyinsNum: string[];
      recommendDefault: string[];
      recommendNum: string[];
    };
  } = {};
  // 手动标注
  res.pinyinInfo.forEach((i) => {
    const needMarkManual = !!PolyphonicMap[i.characterText];
    pinyinMap[i.characterText] = {
      pinyinsDefault: needMarkManual
        ? [...i.pinyinDefaults, ...PolyphonicMap[i.characterText].pinyinDefault]
        : i.pinyinDefaults || [''],
      pinyinsNum: needMarkManual
        ? [...i.pinyinNumbers, ...PolyphonicMap[i.characterText].pinyinNumber]
        : i.pinyinNumbers || [''],
      recommendDefault: i.pinyinRecommendDefaults.map((i) => i.recommendInfo),
      recommendNum: i.pinyinRecommendNumbers.map((i) => i.recommendInfo),
    };
  });
  // 单个字检测增加轻声
  if (data.pinyinText.length === 1) {
    const { pinyinsNum, pinyinsDefault } = pinyinMap[data.pinyinText];
    // 去重得到不包含音调的读音
    const barePinyinArray = Array.from(new Set(pinyinsNum.map((i: string) => i.replace(/[0-9]+/g, '')))).filter(
      (n) => !pinyinsNum.includes(`${n}5`),
    );
    // console.log('barePinyinArray', barePinyinArray);
    pinyinMap[data.pinyinText].pinyinsDefault = [...pinyinsDefault, ...barePinyinArray];
    pinyinMap[data.pinyinText].pinyinsNum = [...pinyinsNum, ...barePinyinArray.map((m: string) => `${m}5`)];
    // console.log(pinyinMap);
  }
  return pinyinMap;
};

// 播报动作预测根据文本内容查询
export const getAnalyzedSsml = async (data: DescribeAnalyzerByTextReq) => {
  const res = await capi<DescribeAnalyzerByTextReq, DescribeAnalyzerByTextRes>('DescribeAnalyzerByText', data);
  return res;
};

// 自动配置
export const asyncMatchAction = async (data: MatchActionReq) => {
  const res = await capi<MatchActionReq, MatchActionRes>('DescribeAnalyzerByIds', data);
  return res;
};

// 重置配置
export const resetAction = async (data: ResetActionReq) => {
  const res = await capi<ResetActionReq, CommonRes>('ResetActionConfig', data);
  return res;
};

// 导出回复语
export const exportReply = async (data: ExportReplyReq) => {
  const res = await capi<ExportReplyReq, ExportReplyRes>('ExportReplys', data);
  return res;
};

// 导入回复语
export const importReply = async (data: ImportReplyReq) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<ImportReplyReq, ImportReplyRes>('ImportReplys', data, true);
  return res;
};

// tts耗时数据查询
export const getTtsDescribe = async (data: GetTtsDescribeReq) => {
  const res = await capi<GetTtsDescribeReq, GetTtsDescribeRes>('DescribeTtsSubtitle', data);
  return res;
};

// 大模型接口-查询问答分类
export const getListQACate = async (data: GetListQACateReq) => {
  const res = await capi<GetListQACateReq, GetListQACateRes>('ListQACate', data, false, 'model');
  return res;
};

// 大模型接口-查询关联文档
export const getSelectListDoc = async (data: GetListSelectDocReq) => {
  const res = await capi<GetListSelectDocReq, GetListSelectDocRes>('ListSelectDoc', data, false, 'model');
  return res;
};

// 大模型接口-发布信息
export const getListNotify = async (data: GetListNotifyReq) => {
  const res = await capi<GetListNotifyReq, GetListNotifyRes>('ListNotify', data, false, 'model');
  return res;
};

// 大模型接口-发布状态
export const getReleaseInfo = async (data: GetReleaseInfoReq) => {
  const res = await capi<GetReleaseInfoReq, GetReleaseInfoRes>('DescribeReleaseInfo', data, false, 'model');
  return res;
};

// 大模型接口-用户权限信息
export const getUserAccount = async (data: GetUserAccountReq) => {
  const res = await capi<GetUserAccountReq, GetUserAccountRes>('DescribeAccount', data, false, 'model');
  return res;
};
