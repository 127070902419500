import capi from '../capi/instance';
import { CommonRes } from 'types/index';

export type GetUserInfoReq = {};
export interface UserInfo {
  accountType: number;
  adminTypes: number[];
  isInnerUser: number;
  name: string;
  ownerUin: string;
  uin: string;
}

export interface GetUserInfoRes extends CommonRes {
  userInfo: UserInfo;
}

export type GetUserPermissionReq = {
  permissionIds: string[];
};
export interface GetUserPermissionRes extends CommonRes {
  permissions: {
    permissionId: string;
  }[];
}

// 用户登陆信息查询
export const getUserInfo = async () => {
  const res = await capi<GetUserInfoReq, GetUserInfoRes>('DescribeLoginUserInfo', {});
  return res;
};

// 用户权限列表
export const getUserPermissions = async (data: GetUserPermissionReq) => {
  const res = await capi<GetUserPermissionReq, GetUserPermissionRes>('QueryPermissions', data);
  return res;
};

interface GetTencentCloudSubAccountReq {
  ownerUin: string;
  skey: string;
}
export interface SubAccount {
  uin: string;
  name: string;
}

interface GetTencentCloudSubAccountRes {
  subList: SubAccount[];
}
// 获取所有子账户
export const getTencentCloudSubAccount = async (data: GetTencentCloudSubAccountReq) => {
  const res = await capi<GetTencentCloudSubAccountReq, GetTencentCloudSubAccountRes>('GetTencentCloudSubAccount', data);
  return res;
};
