// 运营分析
import capi from '../capi/instance';

import { CommonRes, PageType } from '../../types/index';
import { StatisticDict, Instance, VirtualmanType, Anchor, DetailStatistic } from '../../types/analysis';

// 字典信息查询 Req
export type DescribeStatisticDictReq = {};

// 字典信息查询 Res
interface DescribeStatisticDictRes extends CommonRes {
  statisticDicts: StatisticDict[];
}
// 字典信息查询
export const describeStatisticDict = async (data: DescribeStatisticDictReq) => {
  const res = await capi<DescribeStatisticDictReq, DescribeStatisticDictRes>('DescribeStatisticDict', data);
  return res;
};

// uin列表分页查询 Req
interface DescribeStatisticUinsReq extends PageType {
  uin: string;
  ownerUin: string[];
  userType: number; // 0 主账号 1 超级管理员
}

// uin列表分页查询 Res
interface DescribeStatisticUinsRes extends CommonRes, PageType {
  uins: string[];
}

// uin列表分页查询
export const describeStatisticUins = async (data: DescribeStatisticUinsReq) => {
  const res = await capi<DescribeStatisticUinsReq, DescribeStatisticUinsRes>('DescribeStatisticUins', data);
  return res;
};

// 项目列表分页查询 Req
interface DescribeStatisticInstancesReq extends PageType {
  uins: string[];
  instanceName: string;
}

// 项目列表分页查询 Res
interface DescribeStatisticInstancesRes extends CommonRes, PageType {
  instances: Instance[];
}

// 项目列表分页查询
export const describeStatisticInstances = async (data: DescribeStatisticInstancesReq) => {
  const res = await capi<DescribeStatisticInstancesReq, DescribeStatisticInstancesRes>(
    'DescribeStatisticInstances',
    data,
  );
  return res;
};

// 形象类型查询 Req
interface DescribeSatisticVirtualmanTypesReq {
  uins: string[];
  anchorCodes: string[];
}

// 形象类型查询 Res
interface DescribeSatisticVirtualmanTypesRes extends CommonRes {
  virtualmanTypes: VirtualmanType[];
}

// 形象类型查询
export const describeSatisticVirtualmanTypes = async (data: DescribeSatisticVirtualmanTypesReq) => {
  const res = await capi<DescribeSatisticVirtualmanTypesReq, DescribeSatisticVirtualmanTypesRes>(
    'DescribeSatisticVirtualmanTypes',
    data,
  );
  return res;
};

// 主播名称列表 Req
interface DescribeStatisticAnchorsReq extends PageType {
  virtualmanTypeCodes: string[];
  uins: string[];
  anchorName: string;
}

// 主播名称列表 Res
interface DescribeStatisticAnchorsRes extends CommonRes, PageType {
  anchors: Anchor[];
}

// 主播名称列表
export const describeStatisticAnchors = async (data: DescribeStatisticAnchorsReq) => {
  const res = await capi<DescribeStatisticAnchorsReq, DescribeStatisticAnchorsRes>('DescribeStatisticAnchors', data);
  return res;
};

// 查询汇总数据 Req
export type DescribeTotalStatisticReq = {
  uins: string[];
  virtualmanTypeCodes: string[];
  productType: number;
  dimensionType: number;
  anchorCodes: string[];
  driverTypes: number[];
  platformTypes: string[];
  taskTypes: number[];
  startTime: number;
  endTime: number;
  statisticType?: number;
};

// excel导出 Res
interface ExportStatisticRes extends CommonRes {
  exportUrl: string;
}

// excel导出
export const exportStatistic = async (data: DescribeTotalStatisticReq) => {
  const res = await capi<DescribeTotalStatisticReq, ExportStatisticRes>('ExportStatistic', data);
  return res;
};

// 查询汇总数据 Res
interface DescribeTotalStatisticRes extends CommonRes {
  streamCount: number;
  streamDuration: number;
  streamDurationAvg: number;
  streamMaxConcurrency: number;
  streamInteractCount: number;
  broadcastDuration: number;
  broadcastTaskCount: number;
  broadcastMaxConcurrency: number;
  broadcastTotalDuration: number;
  broadcastRemainDuration: number;
}

// 查询汇总数据
export const describeTotalStatistic = async (data: DescribeTotalStatisticReq) => {
  const res = await capi<DescribeTotalStatisticReq, DescribeTotalStatisticRes>('DescribeTotalStatistic', data);
  return res;
};

// 查询看板详细数据 Res
interface DescribeDetailStatisticRes extends CommonRes {
  detailStatistics: DetailStatistic[];
}

// 查询看板详细数据
export const describeDetailStatistic = async (data: DescribeTotalStatisticReq) => {
  const res = await capi<DescribeTotalStatisticReq, DescribeDetailStatisticRes>('DescribeDetailStatistic', data);
  return res;
};

type DescribeStatisticAdminUinsReq = {};
interface DescribeStatisticAdminUinsRes {
  uins: string[];
}
/**
 * uin 白名单，查看数据
 */
export const describeStatisticAdminUins = async () => {
  const res = await capi<DescribeStatisticAdminUinsReq, DescribeStatisticAdminUinsRes>(
    'DescribeStatisticAdminUins',
    {},
  );
  return res;
};
