// 形象设置
import capi from '../capi/instance';

import { CommonRes, PageType, virtualmanType } from '../../types/index';
import {
  ImageDetail,
  ImageSaveDetail,
  VirtualmanItem,
  SpeedItem,
  FileItem,
  ImageProtoItem,
  RoleItem,
  ClothesItem,
} from '../../pages/image_configure/types';
import { ActionGroup, ExpressionGroup } from '../../types/sentence';

// 查询数智人形象设置相关资源 req
export type GetImageResourceReq = {
  virtualmanTypeCode: string;
  hasVirtualmanResource?: boolean;
  hasBackground?: boolean;
  hasLogo?: boolean;
  hasSpeed?: boolean;
  hasTargetFace?: boolean;
  type: number;
};

// 查询数智人形象设置相关资源 res
export type GetImageResourceRes = CommonRes & {
  virtualmanResourceList?: VirtualmanItem[];
  backgroundList?: FileItem[];
  logoList?: FileItem[];
  speedList?: SpeedItem[];
};

// 查询数智人形象配置 req
export type GetImageDetailReq = {};

// 查询数智人形象配置 res
export type GetImageDetailRes = CommonRes & ImageDetail;

// 保存数智人形象配置 req
export type SaveImageDetailReq = ImageSaveDetail;

// 保存数智人形象配置 res
export type SaveImageDetailRes = CommonRes;

// 获取动作库 req
export type GetVirtualmanActionsReq =
  | {
      virtualmanKey?: string;
      virtualmanResourceId?: number;
    }
  | undefined;

// 获取动作库 res
export type GetVirtualmanActionsRes = CommonRes & {
  actionGroups: ActionGroup[];
};

// 获取cos临时密钥 req
export type GetTemporaryKeyOfCosReq = {
  fileName: string;
};

// 获取cos临时密钥 res
export type GetTemporaryKeyOfCosRes = CommonRes & {
  storageType: 'cos' | 'minio';
  // cos
  tmpSecretId: string;
  tmpSecretKey: string;
  sessionToken: string;
  startTime: string;
  expiredTime: string;
  region: string;
  bucket: string;
  path: string;
  // minio
  date: string;
  signature: string;
  host: string;
  algorithm: string;
  credential: string;
  policy: string;
};

// 保存资源 req
export type SaveCustomResourceReq = {
  customResource: {
    customResourceUrl: string;
    customResourcesType: string;
    modelSource: string;
  };
};

// 保存资源 res
export type SaveCustomResourceRes = CommonRes & {
  fileId: string;
  faceKeyPoints: number[];
};

// 删除资源 req
export type DeleteCustomResourceReq = {
  fileId: string;
};

// 删除资源 res
export type DeleteCustomResourceRes = CommonRes;

// 获取预览小程序sign req
export type MiniAppSignReq = {
  appKey?: string;
};

export interface MiniAppSignRes extends CommonRes {
  sign: string;
}

// 获取音色试听req
export type GetTtsAuditionReq = {
  sentence?: string;
  speedCode: string;
  timbreCode: string;
};

export type GetTtsAuditionRes = CommonRes & {
  audio: string;
};

interface GetSceneTvsSignReq {
  virtualmanSceneInstanceKey: string;
}

interface GetSceneTvsSignRes extends CommonRes {
  sign: string;
}

export interface GetConcurrentReq {
  virtualmanKey: string;
  instanceType: string;
  virtualmanResourceId: number;
  virtualmanTypeV2?: string;
}

interface GetConcurrentRes extends CommonRes {
  concurrent: number;
}

export interface DescribeVirtualmanProtoResourceListReq {
  virtualmanTypeCode?: string;
  virtualmanTypeV2: string;
}

interface DescribeVirtualmanProtoResourceListRes extends CommonRes {
  virtualmanResourceList: ImageProtoItem[];
  speedList: SpeedItem[];
}

// 获取表情库 req
export type GetVirtualmanExpressionReq = GetVirtualmanActionsReq;

// 获取表情库 res
export type GetVirtualmanExpressionRes = CommonRes & {
  expressionGroup: ExpressionGroup[];
};

// 查询数智人形象设置相关资源
export const getImageResource = async (data: GetImageResourceReq) => {
  const res = await capi<GetImageResourceReq, GetImageResourceRes>('DescribeVirtualmanResourceList', data);
  return res;
};

// 查询数智人模板形象设置相关资源 req
export type DescribeVirtualmanResourceListReq = GetImageResourceReq & {
  isSmallSample?: number;
};

// 查询角色列表接口 req
export type DescribeVirtualmanResourceRoleListReq = {
  virtualmanTypeCode?: string;
  virtualmanKey?: string;
  hasVirtualmanResource?: boolean;
  type?: number;
  imageType?: string;
  feePage?: boolean;
  imageCode?: string;
  sourceType?: number;
  isSmallSample?: number;
  limit: number;
  offset: number;
  selectImageCode?: string;
  assetsType?: string;
  driverTypes?: string;
  name?: string;
  imageAssetCreateTimeAsc?: boolean;
  virtualmanTypeV2?: string;
};

// 查询角色列表接口 res
export type DescribeVirtualmanResourceRoleListRes = CommonRes &
  PageType & {
    virtualmanResourceList: RoleItem[];
  };

// 查询服装资源列表 req
export type DescribeVirtualmanResourceClothesListReq = {
  virtualmanTypeCode?: string;
  isSmallSample?: number;
  imageCode: string;
  assetsType: string;
  feePage: boolean;
  sourceType: number;
  virtualmanTypeV2?: string;
};

// 查询服装资源列表 res
export type DescribeVirtualmanResourceClothesListRes = CommonRes & {
  virtualmanResourcesImage: VirtualmanItem;
  // virtualmanResourcesImage: {
  //   virtualmanCode: string;
  //   virtualmanName: string;
  //   headerThumbnailUrl: string;
  //   virtualmanType: string;
  //   desc: string;
  //   clothesResources: ClothesItem[];
  //   modelSource: string;
  //   fuseImageType?: string;
  //   assetsType?: string;
  //   imageType?: string;
  // };
};

// 查询背景logo列表 req
export type DescribeVirtualmanBackgroundLogoListReq = {
  hasBackground: boolean;
  virtualmanKey: string;
  hasLogo: boolean;
};

// 查询背景logo列表 req
export type DescribeVirtualmanBackgroundLogoListRes = {
  backgroundList?: FileItem[];
  logoList?: FileItem[];
};

export interface DescribeNewVirtualmanProtoResourceListReq {
  virtualmanTypeCode?: string;
  virtualmanTypeV2?: string;
  type?: number;
  offset?: number;
  limit?: number;
}

interface DescribeNewVirtualmanProtoResourceListRes extends CommonRes {
  virtualmanResourceList: ImageProtoItem[];
  totalCount?: number;
  speedList: SpeedItem[];
}

// 查询数智人形象设置
export const getImageDetail = async (data?: GetImageDetailReq, noAutoHandle?: boolean) => {
  const res = await capi<GetImageDetailReq, GetImageDetailRes>(
    'DescribeVirtualmanResource',
    data || {},
    !!noAutoHandle,
  );
  return res;
};

// 保存数智人形象设置
export const saveImageDetail = async (data: SaveImageDetailReq, noAutoHandle = false) => {
  const res = await capi<SaveImageDetailReq, SaveImageDetailRes>('SaveVirtualmanImage', data, noAutoHandle);
  return res;
};

// 获取动作库
export const getImageActions = async (data?: GetVirtualmanActionsReq) => {
  const res = await capi<GetVirtualmanActionsReq, GetVirtualmanActionsRes>(
    'DescribeVirtualResourceActionsByVirtualmanKey',
    data,
    false,
  );
  return res;
};

// 获取cos临时密钥
export const getTemporaryKeyOfCos = async (data: GetTemporaryKeyOfCosReq) => {
  const res = await capi<GetTemporaryKeyOfCosReq, GetTemporaryKeyOfCosRes>('GetTemporaryKeyOfCos', data);
  return res;
};

// 保存资源
export const saveCustomResource = async (data: SaveCustomResourceReq) => {
  const res = await capi<SaveCustomResourceReq, SaveCustomResourceRes>('SaveCustomResource', data);
  return res;
};

// 删除资源
export const deleteCustomResource = async (data: DeleteCustomResourceReq) => {
  const res = await capi<DeleteCustomResourceReq, DeleteCustomResourceRes>('DeleteCustomResource', data);
  return res;
};

// 获取预览小程序sign
export const getMiniAppSign = async (data = {}) => {
  const res = await capi<MiniAppSignReq, MiniAppSignRes>('QueryQrCodeSign', data);
  return res;
};

// 获取音色试听
export const getTtsAudition = async (data: GetTtsAuditionReq) => {
  const res = await capi<GetTtsAuditionReq, GetTtsAuditionRes>('DescribeTtsAudition', data);
  return res;
};

// 获取预览空间场景sign
export const getSceneTvsSign = async (data: GetSceneTvsSignReq) => {
  const res = await capi<GetSceneTvsSignReq, GetSceneTvsSignRes>('QuerySceneSign', data);
  return res;
};

// 查询可设置最大并发数
export const getConcurrent = async (data: GetConcurrentReq) => {
  const res = await capi<GetConcurrentReq, GetConcurrentRes>('QueryConcurrent', data);
  return res;
};

// 获取数智人相册
export const getVirtualmanProtoResource = async (data: DescribeVirtualmanProtoResourceListReq) => {
  const res = await capi<DescribeVirtualmanProtoResourceListReq, DescribeVirtualmanProtoResourceListRes>(
    'DescribeVirtualmanProtoResourceList',
    data,
  );
  return res;
};
// 获取表情库
export const getImageExpression = async (data?: GetVirtualmanExpressionReq) => {
  const res = await capi<GetVirtualmanExpressionReq, GetVirtualmanExpressionRes>(
    'DescribeVirtualResourceExpressionsByVirtualmanKey',
    data,
    false,
  );
  return res;
};
// 获取数智人形象列表
export const getVirtualmanTemplateResource = async (data: DescribeVirtualmanResourceListReq) => {
  const res = await capi<DescribeVirtualmanResourceListReq, DescribeVirtualmanProtoResourceListRes>(
    'DescribeVirtualmanResourceList',
    // data,
    {
      hasBackground: true,
      hasLogo: true,
      hasSpeed: true,
      hasVirtualmanResource: true,
      type: 1,
      virtualmanTypeCode: data.virtualmanTypeCode,
    },
  );
  return res;
};

// 查询角色列表接口
export const getVirtualmanResourceRoleList = async (data: DescribeVirtualmanResourceRoleListReq) => {
  const res = await capi<DescribeVirtualmanResourceRoleListReq, DescribeVirtualmanResourceRoleListRes>(
    'DescribeVirtualmanResourceRoleList',
    data,
  );
  return res;
};

// 查询服装列表接口
export const getVirtualmanResourceClothesList = async (data: DescribeVirtualmanResourceClothesListReq) => {
  const res = await capi<DescribeVirtualmanResourceClothesListReq, DescribeVirtualmanResourceClothesListRes>(
    'DescribeVirtualmanResourceClothesList',
    data,
  );
  return res;
};

// 查询背景logo列表接口
export const getVirtualmanBackgroundLogoList = async (data: DescribeVirtualmanBackgroundLogoListReq) => {
  const res = await capi<DescribeVirtualmanBackgroundLogoListReq, DescribeVirtualmanBackgroundLogoListRes>(
    'DescribeVirtualmanBackgroundLogoList',
    data,
  );
  return res;
};

// 获取数智人新相册
export const getNewVirtualmanProtoResource = async (data: DescribeNewVirtualmanProtoResourceListReq) => {
  const res = await capi<DescribeNewVirtualmanProtoResourceListReq, DescribeNewVirtualmanProtoResourceListRes>(
    'DescribePhotoOfficeWebImageList',
    data,
  );
  return res;
};
