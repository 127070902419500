// 3D空间服务场景
import capi from '../capi/instance';
import { CommonRes } from 'types/index';

export interface GetSceneInstancesReq {
  offset: number;
  limit: number;
  filter?: {
    virtualmanSceneInstanceName: string;
  };
}
export interface SceneInstance {
  virtualmanSceneInstanceName: string;
  virtualmanSceneInstanceKey: string;
  previewUrl: string;
  virtualmanSceneInstanceDescrible: string;
  sceneCode: string;
  gameGroup?: string;
  createTime: string;
}

interface GetSceneInstancesRes extends CommonRes {
  offset: string;
  limit: string;
  records: SceneInstance[];
}

export type DeleteSceneInstanceReq = {
  virtualmanSceneInstanceKey: string;
};

interface GetScenesReq {
  offset: number;
  limit: number;
  filter?: {
    VirtualmanSceneName: string;
  };
}

export interface SceneItem {
  virtualmanSceneName: string;
  virtualmanSceneCode: string;
  previewUrl: string;
  virtualmanSceneDescrible: string;
  projectUrl: string;
}

export interface GetScenesRes extends CommonRes {
  offset: string;
  limit: string;
  records: SceneItem[];
}

export interface SaveSceneInstanceReq {
  virtualmanSceneInstanceName: string;
  virtualmanSceneInstanceDescrible: string;
  gameGroup?: string;
  virtualmanSceneCode: string;
  virtualmanSceneInstanceKey?: string;
}

interface SaveSceneInstanceRes extends CommonRes {
  virtualmanSceneInstanceKey: string;
}

interface GetSceneInstanceDetailReq {
  virtualmanSceneInstanceKey: string;
}

export interface SceneInfo {
  virtualmanSceneInstanceKey: string;
  virtualmanSceneSpaces: SceneSpace[];
  virtualmanSceneInstanceName: string;
  virtualmanSceneCode: string;
}
export interface SceneSpace {
  spaceCode: string;
  virtualmanKey: string;
  spaceName: string;
  projectUrl: string;
}
export interface GetSceneInstanceDetailRes extends CommonRes, SceneInfo {}

interface GetSceneDetailReq {
  virtualmanSceneCode: string;
}

export interface AdvertiseMentConfig {
  advertisementCode: string;
  previewUrl: string;
  advertisementName: string;
  requirements: {
    supportType: string;
    proportion: string;
    size: string;
  }[];
}
export interface VirtualmanSceneSpaceConfig {
  spaceCode: string;
  spaceName: string;
  spaceDescribe: string;
  previewUrl: string;
  projectUrl: string;
  imagesDefaultInfos: {
    imageDefaultHorizontal: number;
    imageDefaultVertical: number;
    imageDefaultZoom: number;
  }[];
  advertisements: AdvertiseMentConfig[];
}
interface GetSceneDetailRes extends CommonRes {
  virtualmanSceneCode: string;
  virtualmanSceneName: string;
  virtualmanSceneDescribe: string;
  previewUrl: string;
  projectUrl: string;
  virtualmanSceneSpaces: VirtualmanSceneSpaceConfig[];
}
// 空间场景项目列表查询
export const getSceneInstances = async (data: GetSceneInstancesReq) => {
  const res = await capi<GetSceneInstancesReq, GetSceneInstancesRes>('DescribeVirtualmanSceneInstances', data);
  return res;
};

// 空间场景项目删除
export const deleteSceneInstance = async (data: DeleteSceneInstanceReq, noAutoHandle?: boolean) => {
  const res = await capi<DeleteSceneInstanceReq, CommonRes>('DeleteSceneInstance', data, !!noAutoHandle);
  return res;
};

// 3D空间场景选项查询
export const getScenes = async (data: GetScenesReq) => {
  const res = await capi<GetScenesReq, GetScenesRes>('DescribeVirtualmanScenes', data);
  return res;
};

// 创建3D空间场景项目
export const saveSceneInstance = async (data: SaveSceneInstanceReq, noAutoHandle?: boolean) => {
  const res = await capi<SaveSceneInstanceReq, SaveSceneInstanceRes>('SaveSceneInstanceConfig', data, !!noAutoHandle);
  return res;
};

// 获取空间场景实例详情
export const getSceneInstanceDetail = async (data: GetSceneInstanceDetailReq) => {
  const res = await capi<GetSceneInstanceDetailReq, GetSceneInstanceDetailRes>(
    'DescribeVirtualmanSceneInstanceDetail',
    data,
  );
  return res;
};

export const getSceneDetail = async (data: GetSceneDetailReq, noAutoHandle?: boolean) => {
  const res = await capi<GetSceneDetailReq, GetSceneDetailRes>('DescribeVirtualmanSceneDetail', data, !!noAutoHandle);
  return res;
};
