import i18n from 'utils/i18n';
// 个人中心-资产管理
import capi from '../capi/instance';
import { CommonRes, virtualmanType } from 'types/index';
const { t } = i18n;
export enum SortOrder {
  ASC = 0,
  DES = 1,
}
export interface CommonListRes {
  offset: number;
  limit: number;
  totalCount: number;
}
export interface GetServiceQuotaListReq {
  serviceTypeList: string[];
  sortRuleList: {
    sortName: string;
    sortOrder: SortOrder;
  }[];
  limit: number;
  offset: number;
}

export enum QuotaTypeEnum {
  sample = 'ImageAsset',
  sampleTimbre = 'TimbreAsset',
  smallSampleGeneral2DImageAsset = 'SmallSampleGeneral2DImageAsset',
  smallSample4K2DImageAsset = 'SmallSample4K2DImageAsset',
  smallSamplePhoto2DImageAsset = 'SmallSamplePhoto2DImageAsset',
  zeroShotTimbreAsset = 'ZeroShotTimbreAsset',
}

// 在架服务类型
export enum OnlineTypeEnum {
  SmallSampleImageRenewalAsset = 'SmallSampleImageRenewalAsset',
  SmallSampleGeneralImageRenewalAsset = 'SmallSampleGeneralImageRenewalAsset',
  RealMan3DImageRenewalAsset = 'RealMan3DImageRenewalAsset',
  TimbreRenewalAsset = 'TimbreRenewalAsset',
}

export const OnlineTypeMap = {
  [OnlineTypeEnum.SmallSampleImageRenewalAsset]: t('2D形象-专属口型'),
  [OnlineTypeEnum.SmallSampleGeneralImageRenewalAsset]: t('2D形象-通用口型'),
  [OnlineTypeEnum.RealMan3DImageRenewalAsset]: t('3D形象'),
  [OnlineTypeEnum.TimbreRenewalAsset]: t('音色定制'),
};

export const QuotaTypeMap = {
  [QuotaTypeEnum.sample]: t('2D小样本（专属口型）'),
  [QuotaTypeEnum.sampleTimbre]: t('声音复刻（基础版）'),
  [QuotaTypeEnum.smallSampleGeneral2DImageAsset]: t('2D小样本（通用口型）'),
  [QuotaTypeEnum.smallSample4K2DImageAsset]: t('2D小样本（高精版本）'),
  [QuotaTypeEnum.smallSamplePhoto2DImageAsset]: t('2D小样本（照片数字人）'),
  [QuotaTypeEnum.zeroShotTimbreAsset]: t('声音复刻（极速版）'),
};

export enum OrderSourceType {
  offline = 0,
  offcial = 1,
  trial = 2,
  giving = 4,
}
export enum ValidType {
  valid = 0,
  invalid,
}
export interface ServiceQuotaItem {
  /**
   * 2d小样本 | 小样本音色
   */
  serviceType: QuotaTypeEnum;
  effectiveTime: string;
  expireTime: string;
  quotaNum: number;
  remainQuotaNum: number;
  /**
   * 0线下开通，1官网下单
   */
  orderSource: OrderSourceType;
  orderId: string;
  quotaId: number;
  /**
   * 是否有效:0有效,1无效
   */
  isValid: ValidType;
  availableRemainDays: number;
  /**
   * 可分配量
   */
  allocateNum: number;
}

export interface OnlineTypeItem extends Omit<ServiceAssetItem, 'serviceType'> {
  serviceType: OnlineTypeEnum;
}

interface GetServiceQuotaListRes extends CommonListRes {
  serviceQuotaList: ServiceQuotaItem[];
}

// 获取配额服务资产列表
export const getServiceQuotaList = async (data: GetServiceQuotaListReq) => {
  const res = await capi<GetServiceQuotaListReq, GetServiceQuotaListRes>('DescribeServiceQuotaList', data);
  return res;
};

export enum VirtualmanType {
  real_man_2d = 'real_man_2d',
  small_sample_2d = 'small_sample_2d',
  real_man_3d = 'real_man_3d',
  cartoon_3d = 'cartoon_3d',
  half_realistic_3d = 'half_realistic_3d',
  small_sample_general_2d = 'small_sample_general_2d',
  small_sample_4k_2d = 'small_sample_4k_2d',
  small_sample_photo_2d = 'small_sample_photo_2d',
}
export enum ImageType {
  general = 'general',
  custom = 'custom',
}
export interface GetImageAssetListReq {
  imageTypeList?: ImageType[];
  virtualmanTypeList?: VirtualmanType[];
  name?: string;
  virtualmanKey?: string;
  sortRuleList: {
    sortName: string;
    sortOrder: SortOrder;
  }[];
  limit: number;
  offset: number;
}

export interface ImageResolution {
  resolution: string;
  virtualmanKey: string;
  resourceId: number;
  effectiveTime: string;
  expireTime: string;
  /**
   * 是否有效: 0有效,1无效
   */
  isValid: ValidType;
  subAccountList: {
    subUin: string;
    subUserName: string;
  }[];
  creatorUin: string;
  creator: string;
}
export interface ImageAssetItem {
  imageType: ImageType;
  /**
   * 2D精品-real_man_2d，2D小样本-small_sample_2d，3D写实-real_man_3d，3D卡通-cartoon_3d，3D半写实-half_realistic_3d
   */
  virtualmanType: VirtualmanType;
  /**
   * 头像code
   */
  virtualmanResourceCode: string;
  /**
   * 头像名称
   */
  virtualmanResourceName: string;
  defaultTimbreCode: string;
  defaultTimbreName: string;
  imageAssetResolutionList: ImageResolution[];
  previewUrl: string;
  availableRemainDays: number;
  clothesCode: string;
  clothesName: string;
  poseCode: string;
  poseName: string;
  anchorCode: string;
  vid: string; // 两个vkey 相加
  renewalDuration?: number; // 续期时长
}
interface GetImageAssetListRes extends CommonListRes {
  imageAssetConfigList: ImageAssetItem[];
}

// 获取形象资产列表
export const getImageAssetList = async (data: GetImageAssetListReq) => {
  const res = await capi<GetImageAssetListReq, GetImageAssetListRes>('DescribeImageAssetList', data);
  return res;
};

/**
 * 1-精品，2-定制
 */
export enum TimbreAssetsType {
  Premium = 1,
  Custom = 2,
}
/**
 * 音色子资产类型：1-小样本音色，2精品音色
 */
export enum TimbreSubAssetsType {
  Sample = 1,
  Premium = 2,
  Fast = 3,
}

export interface GetTimbreAssetListReq {
  sortRuleList: {
    sortName: string;
    sortOrder: SortOrder;
  }[];
  limit: number;
  offset: number;
  timbreAssetsTypeList: TimbreAssetsType[];
  timbreSubAssetsTypeList: TimbreSubAssetsType[];
  name: string;
}

export interface TimbreAssetItem {
  timbreAssetsType: TimbreAssetsType;
  timbreSubAssetsType: TimbreSubAssetsType;
  name: string;
  expireTime: string;
  effectiveTime: string;
  timbreDemo: string;
  timbreCode: string;
  timbreCodeName: number;
  /**
   * 是否有效:0有效,1无效
   */
  isValid: ValidType;
  availableRemainDays: number;
  defaultSpeed: string;
  subAccountList: {
    subUin: string;
    subUserName: string;
  }[];
  creatorUin: string;
  creator: string;
  renewalDuration?: number; // 续期时长
  vid: string;
}
interface GetTimbreAssetListRes extends CommonListRes {
  timbreAssetConfigList: TimbreAssetItem[];
}

// 获取音色资产列表
export const getTimbreAssetList = async (data: GetTimbreAssetListReq) => {
  const res = await capi<GetTimbreAssetListReq, GetTimbreAssetListRes>('DescribeTimbreAssetList', data);
  return res;
};

/**
 * InteractConcurrencyType             ServiceType = 1  //2D小样本交互
  BroadcastHourType                   ServiceType = 2  //2D小样本播报小时包
  BroadcastConcurrencyType            ServiceType = 3  //2D小样本播报并发
  ImageAssetType                      ServiceType = 4  //形象定制
  TimbreAssetType                     ServiceType = 5  //音色定制
  RealMan2DInteractConcurrency        ServiceType = 6  //2D精品交互并发
  RealMan3DInteractConcurrency        ServiceType = 7  //3D写实交互并发
  HalfRealistic3DInteractConcurrency  ServiceType = 8  //3D半写实交互并发
  Cartoon3DInteractConcurrency        ServiceType = 9  //3D卡通交互并发
  RealMan2DBroadcastHour              ServiceType = 10 //2D精品播报小时包
  RealMan3DBroadcastHour              ServiceType = 11 //3D写实播报小时包
  HalfRealistic3DBroadcastHour        ServiceType = 12 //3D半写实播报小时包
  Cartoon3DBroadcastHour              ServiceType = 13 //3D卡通播报小时包
  RealMan2DBroadcastConcurrency       ServiceType = 14 //2D精品播报并发
  RealMan3DBroadcastConcurrency       ServiceType = 15 //3D写实播报并发
  HalfRealistic3DBroadcastConcurrency ServiceType = 16 //3D半写实播报并发
  Cartoon3DBroadcastConcurrency       ServiceType = 17 //3D卡通播报并发
  TimbreHourType                      ServiceType = 18
  TimbreConcurrencyType               ServiceType = 19
 */
export const ServiceAssetsType = {
  InteractiveConcurrency: t('交互并发'),
  BroadcastConcurrency: t('播报并发'),
  BroadcastHour: t('播报小时包'),
  TimbreHour: t('声音合成小时包'),
};

export enum ServiceAssetsSubEnum {
  Timbre = 'timbre',
}

export type ServiceAssetsSubType = ServiceAssetsSubEnum | VirtualmanType;

export const ServiceAssetsMapData = {
  InteractConcurrency: { prefix: ServiceAssetsType.InteractiveConcurrency, suffix: VirtualmanType.small_sample_2d },
  BroadcastHour: { prefix: ServiceAssetsType.BroadcastHour, suffix: VirtualmanType.small_sample_2d },
  BroadcastConcurrency: { prefix: ServiceAssetsType.BroadcastConcurrency, suffix: VirtualmanType.small_sample_2d },
  RealMan2DInteractConcurrency: {
    prefix: ServiceAssetsType.InteractiveConcurrency,
    suffix: VirtualmanType.real_man_2d,
  },
  RealMan3DInteractConcurrency: {
    prefix: ServiceAssetsType.InteractiveConcurrency,
    suffix: VirtualmanType.real_man_3d,
  },
  HalfRealistic3DInteractConcurrency: {
    prefix: ServiceAssetsType.InteractiveConcurrency,
    suffix: VirtualmanType.half_realistic_3d,
  },
  Cartoon3DInteractConcurrency: { prefix: ServiceAssetsType.InteractiveConcurrency, suffix: VirtualmanType.cartoon_3d },
  RealMan2DBroadcastHour: { prefix: ServiceAssetsType.BroadcastHour, suffix: VirtualmanType.real_man_2d },
  RealMan3DBroadcastHour: { prefix: ServiceAssetsType.BroadcastHour, suffix: VirtualmanType.real_man_3d },
  HalfRealistic3DBroadcastHour: { prefix: ServiceAssetsType.BroadcastHour, suffix: VirtualmanType.half_realistic_3d },
  Cartoon3DBroadcastHour: { prefix: ServiceAssetsType.BroadcastHour, suffix: VirtualmanType.cartoon_3d },
  RealMan2DBroadcastConcurrency: { prefix: ServiceAssetsType.BroadcastConcurrency, suffix: VirtualmanType.real_man_2d },
  RealMan3DBroadcastConcurrency: { prefix: ServiceAssetsType.BroadcastConcurrency, suffix: VirtualmanType.real_man_3d },
  HalfRealistic3DBroadcastConcurrency: {
    prefix: ServiceAssetsType.BroadcastConcurrency,
    suffix: VirtualmanType.half_realistic_3d,
  },
  Cartoon3DBroadcastConcurrency: { prefix: ServiceAssetsType.BroadcastConcurrency, suffix: VirtualmanType.cartoon_3d },
  SmallSampleGeneral2DInteractConcurrency: {
    prefix: ServiceAssetsType.InteractiveConcurrency,
    suffix: VirtualmanType.small_sample_general_2d,
  },
  SmallSampleGeneral2DBroadcastHour: {
    prefix: ServiceAssetsType.BroadcastHour,
    suffix: VirtualmanType.small_sample_general_2d,
  },
  SmallSampleGeneral2DBroadcastConcurrency: {
    prefix: ServiceAssetsType.BroadcastConcurrency,
    suffix: VirtualmanType.small_sample_general_2d,
  },
  SmallSample4K2DInteractConcurrency: {
    prefix: ServiceAssetsType.InteractiveConcurrency,
    suffix: VirtualmanType.small_sample_4k_2d,
  },
  SmallSample4K2DBroadcastHour: {
    prefix: ServiceAssetsType.BroadcastHour,
    suffix: VirtualmanType.small_sample_4k_2d,
  },
  SmallSample4K2DBroadcastConcurrency: {
    prefix: ServiceAssetsType.BroadcastConcurrency,
    suffix: VirtualmanType.small_sample_4k_2d,
  },
  SmallSamplePhoto2DInteractConcurrency: {
    prefix: ServiceAssetsType.InteractiveConcurrency,
    suffix: VirtualmanType.small_sample_photo_2d,
  },
  SmallSamplePhoto2DBroadcastHour: {
    prefix: ServiceAssetsType.BroadcastHour,
    suffix: VirtualmanType.small_sample_photo_2d,
  },
  SmallSamplePhoto2DBroadcastConcurrency: {
    prefix: ServiceAssetsType.BroadcastConcurrency,
    suffix: VirtualmanType.small_sample_photo_2d,
  },
  TimbreHour: { prefix: ServiceAssetsType.TimbreHour, suffix: 'general' },
};
export type ServiceAssetsMapDataKeys = keyof typeof ServiceAssetsMapData;

export interface GetServiceAssetListReq {
  sortRuleList: {
    sortName: string;
    sortOrder: number;
  }[];
  limit: number;
  offset: number;
  serviceTypList: ServiceAssetsMapDataKeys[];
}

export interface ServiceAssetItem {
  serviceType: ServiceAssetsMapDataKeys;
  effectiveTime: string;
  expireTime: string;
  orderSource: OrderSourceType;
  orderId: number;
  quotaId: number;
  orderMilliseconds: number;
  remainMilliseconds: number;
  isValid: ValidType;
  availableRemainDays: number;
  concurrentUsedDays: number;
  concurrentRemainDays: number;
  allocateNum: number;
  ownerConsumeNum: number;
  concurrencyNum: number;
}
interface GetServiceAssetListRes {
  serviceAssetList: ServiceAssetItem[];
  offset: number;
  limit: number;
  totalCount: number;
}

// 获取服务资产列表
export const getServiceAssetList = async (data: GetServiceAssetListReq) => {
  const res = await capi<GetServiceAssetListReq, GetServiceAssetListRes>('DescribeServiceAssetList', data);
  return res;
};

export interface GetInactiveServiceAssetListReq {
  sortRuleList: {
    sortName: string;
    sortOrder: number;
  }[];
  limit: number;
  offset: number;
  serviceTypList: ServiceAssetsMapDataKeys[];
  sourceStatus: string;
}
export interface InactiveServiceAssetItem {
  serviceType: ServiceAssetsMapDataKeys;
  effectiveTime: string;
  expireTime: string;
  orderSource: OrderSourceType;
  orderId: number;
  availableRemainDays: number;
  sourceStatus: number;
  activeTime: number;
  duration: number;
  concurrency: string;
  unit: number;
  subOrderId: string;
  orderState: number;
}
interface GetInactiveServiceAssetListRes {
  billingFlowList: InactiveServiceAssetItem[];
  offset: number;
  limit: number;
  totalCount: number;
}
// 获取未激活服务资产列表
export const getInactiveServiceAssetList = async (data: GetInactiveServiceAssetListReq) => {
  const res = await capi<GetInactiveServiceAssetListReq, GetInactiveServiceAssetListRes>(
    'DescribeNotActiveServiceAssetList',
    data,
  );
  return res;
};

type PostActiveItemReq = {
  subOrderId: string;
};
// 激活未激活服务资产
export const postActiveItem = async (data: PostActiveItemReq) => {
  const res = await capi<PostActiveItemReq, {}>('ActivateServiceAsset', data);
  return res;
};

export interface GetConsumptionRecordListReq {
  quotaId: number;
  limit: number;
  offset: number;
}

export interface ConsumptionRecordItem {
  consumptionTime: string;
  workOrderId: string;
  operationType: number;
  /**
   * 消耗账户
   */
  consumeAccount: string;
  /**
   * 消耗账户uin
   */
  consumeAccountName: string;
  imageInfo: {
    previewUrl: string;
    virtualmanResourceCode: string;
    virtualmanResourceName: string;
  };
  timbreInfo: {
    timbreName: string;
  };
}

interface GetConsumptionRecordListRes extends CommonListRes {
  consumptionRecordList: ConsumptionRecordItem[];
}

// 获取消费记录
export const getConsumptionRecordList = async (data: GetConsumptionRecordListReq) => {
  const res = await capi<GetConsumptionRecordListReq, GetConsumptionRecordListRes>(
    'DescribeConsumptionRecordList',
    data,
  );
  return res;
};

export interface GetAllocateRecordsReq {
  assetId: number;
}

export interface AllocateRecord {
  allocateTime: string;
  num: number;
  uin: string;
  name: string;
  allocateId: number;
  expireTime: string;
}

interface GetAllocateRecordsRes extends CommonListRes {
  recordList: AllocateRecord[];
  totalNum: number;
  /**
   * 主账号消耗额度
   */
  consumeNum: number;
  /**
   * 已分配额度
   */
  allocatedNum: number;
  /**
   * 可分配额度
   */
  lastAllocateNum: number;
}

// 获取消费记录
export const getAllocateRecords = async (data: GetAllocateRecordsReq) => {
  const res = await capi<GetAllocateRecordsReq, GetAllocateRecordsRes>('DescribeAllocateRecord', data);
  return res;
};

type GetAssetSummaryReq = {};

export interface GetAssetSummaryRes {
  assetSummary: {
    /**
     * 可用2d小样本定额配置数量
     */
    availableSampleQuotaNum: number;
    /**
     * 可用2d小样本音色数量
     */
    availableSampleTimbreQuotaNum: number;
    /**
     * 在用形象定制形象资产数量
     */
    availableCustomImageAssetNum: number;
    /**
     * 在用形象租赁形象资产数量
     */
    availableGeneralImageAssetNum: number;
    /**
     * 在用即将失效形象资产数量
     */
    availableImpendingExpireImageAssetNum: number;
    /**
     * 在用声音资产数量
     */
    availableTimbreAssetNum: number;
    /**
     * 在用即将失效声音资产数量
     */
    availableImpendingExpireTimbreAssetNum: number;
    /**
     * 在用并发服务资产数量
     */
    availableConcurrencyServiceAssetNum: number;
    /**
     * 在用播报小时服务资产数量
     */
    availableBroadcastDurationServiceAssetNum: number;
    /**
     * 在用最近即将失效的时间
     */
    availableImpendingExpireTime: string;
    /**
     * 主账号时可分配的小样本配额数量
     */
    allocateSampleQuotaNum: number;
    /**
     * 主账号时可分配的小样本音色配额数量
     */
    allocateSampleTimbreQuotaNum: number;
    /**
     * 主账号时可分配的在用并发服务资产数量
     */
    allocateConcurrencyAssetNum: number;
    /**
     * 主账号时可分配的播报小时服务资产数量
     */
    allocateBroadcastDurationAssetNum: number;
  };
}

// 获取资产概况
export const getAssetSummary = async () => {
  const res = await capi<GetAssetSummaryReq, GetAssetSummaryRes>('DescribeAssetSummary', {});
  return res;
};

type SaveImageAssetReq = {
  virtualmanKeys: string[];
  defaultTimbreCode: string;
};

type SaveImageAssetRes = {} & CommonRes;

// 保存形象资产
export const saveImageAsset = async (data: SaveImageAssetReq) => {
  const res = await capi<SaveImageAssetReq, SaveImageAssetRes>('SaveImageAsset', data);
  return res;
};

type SaveTimbreReq = {
  timbreCode: string;
  defaultSpeed: string;
};

type SaveTimbreRes = {} & CommonRes;
// 保存音色资产
export const saveTimbreAsset = async (data: SaveTimbreReq) => {
  const res = await capi<SaveTimbreReq, SaveTimbreRes>('SaveTimbreAsset', data);
  return res;
};

type GetSecretReq = {};

export interface GetSecretRes {
  inGray: boolean;
  customerSecrets: {
    accessKey: string;
    secretKey: string;
  }[];
}

// 获取资产概况
export const getSecret = async () => {
  const res = await capi<GetSecretReq, GetSecretRes>('DescribeSecret', {});
  return res;
};

// 新客领用试用资产
type GetTrialAssetsReq = null;
type TrialType = 0 | 1;

export interface TrailInfo {
  /**
   * 0:音视频播报，1:会话互动
   */
  trialType: TrialType;
  effectTime: string;
  efficacyTime: string;
  /**
   * 领用状态 0：未领用，1：已领用，2：存在正式购买（置灰）
   */
  status: 0 | 1 | 2;
  /**
   * 剩余续期(领用)次数
   */
  lastCount: number;
}

export interface GetTrialAssetsRes {
  trialList: TrailInfo[];
}

// 获取资产概况
export const getTrialAssets = async () => {
  const res = await capi<GetTrialAssetsReq, GetTrialAssetsRes>('DescribeCustomNewTrial', null);
  return res;
};

type setTrialReq = {
  trialType: TrialType;
};
type setTrialRes = {} & CommonRes;

// 领用
export const setTrial = async (data: setTrialReq) => {
  const res = await capi<setTrialReq, setTrialRes>('RenewTrial', data, true);
  return res;
};

type ModifySubExpireTimeReq = {
  allocateId: number;
  expireTime: string;
  operationMsg: string;
};
type ModifySubExpireTimeRes = {} & CommonRes;
export const modifySubExpireTime = async (data: ModifySubExpireTimeReq) => {
  const res = await capi<ModifySubExpireTimeReq, ModifySubExpireTimeRes>('ModifySubExpireTime', data);
  return res;
};

type AllocateImageReq = {
  resourceId: number;
  subUin: string[];
};
type AllocateImageRes = {} & CommonRes;
// 形象增加子用户
export const assignImageToSub = async (data: AllocateImageReq) => {
  const res = await capi<AllocateImageReq, AllocateImageRes>('AllocateImage', data);
  return res;
};
// 修改分配给子用户的形象的过期时间
type ModifySubImageExpireTimeReq = {
  resourceId: number;
  subUin: string[];
  expireTime: string;
};
type ModifySubImageExpireTimeRes = {} & CommonRes;
export const modifySubImageExpire = async (data: ModifySubImageExpireTimeReq) => {
  const res = await capi<ModifySubImageExpireTimeReq, ModifySubImageExpireTimeRes>('ModifySubImageExpireTime', data);
  return res;
};

type AllocateTimbreReq = {
  timbreCode: string;
  subUin: string[];
};
type AllocateTimbreRes = {} & CommonRes;
// 音色增加子用户
export const assignTimbreToSub = async (data: AllocateTimbreReq) => {
  const res = await capi<AllocateTimbreReq, AllocateTimbreRes>('AllocateTimbre', data);
  return res;
};
// 修改分配给子用户的形象的过期时间
type ModifySubTimbreExpireTimeReq = {
  timbreCode: string;
  subUin: string[];
  expireTime: string;
};
type ModifySubTimbreExpireTimeRes = {} & CommonRes;
export const modifySubTimbreExpire = async (data: ModifySubTimbreExpireTimeReq) => {
  const res = await capi<ModifySubTimbreExpireTimeReq, ModifySubTimbreExpireTimeRes>('ModifySubTimbreExpireTime', data);
  return res;
};

// 分配给子用户配额或者服务资产
type AllocateQuotaServiceReq = {
  assetId: number;
  num: number;
  subUin: string;
};
type AllocateQuotaServiceRes = { operationQuotaId: string } & CommonRes;
export const allocateQuotaService = async (data: AllocateQuotaServiceReq) => {
  const res = await capi<AllocateQuotaServiceReq, AllocateQuotaServiceRes>('AllocateQuotaService', data);
  return res;
};
// 形象分配配额
type RenewImageAssetsReq = {
  renewItems: { virtualmanKeys: string[]; monthNum: number; originExpireTime: string }[];
};
type RenewImageAssetsRes = CommonRes;
export const renewImageAssets = async (data: RenewImageAssetsReq) => {
  const res = await capi<RenewImageAssetsReq, RenewImageAssetsRes>('RenewImageAssets', data);
  return res;
};
// 音色分配配额
type RenewTimbreAssetsReq = {
  renewItems: { timbreCode: string; monthNum: number; originExpireTime: string }[];
};
type RenewTimbreAssetsRes = CommonRes;
export const renewTimbreAssets = async (data: RenewTimbreAssetsReq) => {
  const res = await capi<RenewTimbreAssetsReq, RenewTimbreAssetsRes>('RenewTimbreAssets', data);
  return res;
};

export enum QuotaSummaryType {
  small_sample_2d = 'SmallSampleImageRenewalAsset',
  small_sample_general_2d = 'SmallSampleGeneralImageRenewalAsset',
  '3d' = 'RealMan3DImageRenewalAsset',
  timbre = 'TimbreRenewalAsset',
}
// 获取服务配额
type DescribeServiceQuotaSummaryReq = {
  serviceTypeList: QuotaSummaryType[];
};
type ServiceQuotaSummaryItem = {
  remainQuotaNum: number;
  serviceType: QuotaSummaryType;
  totalQuotaNum: number;
};
export type DescribeServiceQuotaSummaryResRes = {
  details: ServiceQuotaSummaryItem[];
  remainQuotaNum: number;
  totalQuotaNum: number;
};
export const getServiceQuotaSummary = async (data: DescribeServiceQuotaSummaryReq) => {
  const res = await capi<DescribeServiceQuotaSummaryReq, DescribeServiceQuotaSummaryResRes>(
    'DescribeServiceQuotaSummary',
    data,
  );
  return res;
};
