// 我的数智人
import capi from '../capi/instance';
import { CommonRes, PageType } from 'types/index';
import { TemplateItem, TemplateDetail } from 'types/template';

// 查询形象模板详情 req
export type DescribeImageTemplateDetailReq = {
  imageTemplateId: number;
};

// 查询形象模板详情 res
export type DescribeImageTemplateDetailRes = CommonRes & TemplateDetail;

// 查询形象模板详情
export const getTemplateDetail = async (data: DescribeImageTemplateDetailReq) => {
  const res = await capi<DescribeImageTemplateDetailReq, DescribeImageTemplateDetailRes>(
    'DescribeImageTemplateDetail',
    data,
    false,
  );
  return res;
};

// 保存/更新形象模板 req
export type SaveImageTemplateReq = TemplateDetail;

// 保存/更新形象模板 res
export type SaveImageTemplateRes = CommonRes & {
  imageTemplateId: number;
};

// 保存/更新形象模板
export const saveImageTemplate = async (data: SaveImageTemplateReq) => {
  const res = await capi<SaveImageTemplateReq, SaveImageTemplateRes>('SaveImageTemplate', data, true);
  return res;
};

// 分页查询数智人形象模板列表 req
export type DescribeImageTemplatesReq = {
  offset: number;
  limit: number;
};

// 分页查询数智人形象模板列表 res
export type DescribeImageTemplatesRes = CommonRes &
  PageType & {
    records: TemplateItem[];
  };

// 分页查询数智人形象模板列表
export const getImageTemplateList = async (data: DescribeImageTemplatesReq) => {
  const res = await capi<DescribeImageTemplatesReq, DescribeImageTemplatesRes>('DescribeImageTemplates', data, false);
  return res;
};

// 查询形象模板权限 req
export type DescribeImageTemplatePermissionReq = DescribeImageTemplateDetailReq;
export type Permissions = {
  supportType: number;
  validOfPeriod: number;
}[];
// 查询形象模板权限 res
export type DescribeImageTemplatePermissionRes = CommonRes & {
  permissions: Permissions;
  isOwnTemplate: boolean;
};

// 查询形象模板权限
export const getImageTemplatePermission = async (data: DescribeImageTemplatePermissionReq) => {
  const res = await capi<DescribeImageTemplatePermissionReq, DescribeImageTemplatePermissionRes>(
    'DescribeImageTemplatePermission',
    data,
    false,
  );
  return res;
};

// 检查名称是否重复 req
export type CheckImageTemplateNameRepeatReq = {
  name: string;
  imageTemplateId?: number;
};

// 检查名称是否重复 res
export type CheckImageTemplateNameRepeatRes = CommonRes & {
  isRepeat: boolean;
};

// 检查名称是否重复
export const checkImageTemplateNameRepeat = async (data: CheckImageTemplateNameRepeatReq) => {
  const res = await capi<CheckImageTemplateNameRepeatReq, CheckImageTemplateNameRepeatRes>(
    'CheckImageTemplateNameRepeat',
    data,
    false,
  );
  return res;
};

// 删除形象模板 req
export type DeleteImageTemplateReq = DescribeImageTemplateDetailReq;

// 删除形象模板 res
export type DeleteImageTemplateRes = CommonRes;

// 删除形象模板
export const deleteImageTemplate = async (data: DeleteImageTemplateReq) => {
  const res = await capi<DeleteImageTemplateReq, DeleteImageTemplateRes>('DeleteImageTemplate', data, false);
  return res;
};
