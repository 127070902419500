import capi from '../capi/instance';

import { CommonRes } from '../../types/index';

export const DEFAULT_TIMBRE_CATEGORY = '0'; // 默认分类
export const EMOTIONAL_TIMBRE_CATEGORY = '2'; // 多情感分类
export const ENG_TIMBRE_CATEGORY = '8'; // 外语分类
export const CUSTOM_TIMBRE_CATEGORY = '1'; // 定制分类

export interface GetTimbreListReq {
  offset: number;
  limit: number;
  timbreCategory?: string;
  virtualmanTypeCode?: string;
  driverType?: string;
  virtualmanCode?: string; // 数智人code，筛选形象性别用
  virtualmanResourceId?: number;
}

interface GetTimbreListRes extends CommonRes {
  timbres: {
    timbreCode: string;
    timbreName: string;
    timbreValue: string;
    timbreUrl: string;
    timbreDesc: string;
    timbreSource: string;
    timbreType: string;
    isRecommend: number;
    timbreCategory: string;
    platformSource: number;
    timbreSpeed: string[];
    supportVirtualmanTypeCodes: string[];
    supportDriverTypes: string[];
    timbreAssetsType: number;
    timbreGender: number;
    emotionalStyles: {
      code: string;
      name: string;
    }[];
    language: string | 'chi' | 'eng' | 'jp' | 'thai' | 'ko' | 'ta_sg' | 'ta_my'; // chi-中文 eng-英文 jp-日语 thai-泰语 ko-朝鲜语 ta_sg 新加坡 ta_my 马来西亚
  }[];
  totalCount: number;
  limit: number;
  offset: number;
}

export interface TimbreSetting {
  volume?: number;
  emotionIntensity?: number;
  emotionCategory?: string;
  platformSource?: number;
  timbreCode?: string;
}
interface SaveTtsAuditionReq {
  sentence: string;
  speedCode: string;
  timbreCode: string;
  timbreCategory?: string;
  timbreSource?: number;
  affectiveStyle?: string;
  affectiveLevel?: number;
  volume?: number;
  ticket: string;
  randomData: string;
}

interface SaveTtsAuditionRes extends CommonRes {
  auditionKey: string;
}

interface DescribeTtsAuditionDetailReq {
  auditionKey: string;
}

interface DescribeTtsAuditionDetailRes extends CommonRes {
  audio: string;
  status: number;
  reason: string;
}

// 用户音色列表查询
export const getTimbreList = async (data: GetTimbreListReq) => {
  const res = await capi<GetTimbreListReq, GetTimbreListRes>('DescribeTimbreList', data);
  return res;
};

// 相册音色列表查询
export const getProtoTimbreList = async (data: GetTimbreListReq) => {
  const res = await capi<GetTimbreListReq, GetTimbreListRes>('DescribeVirtualmanProtoTimbreList', data);
  return res;
};

// 生成试听key
export const getAuditionKey = async (data: SaveTtsAuditionReq) => {
  const res = await capi<SaveTtsAuditionReq, SaveTtsAuditionRes>('SaveTtsAudition', data);
  return res;
};

// 查询试听生成进度
export const getAudition = async (data: DescribeTtsAuditionDetailReq) => {
  const res = await capi<DescribeTtsAuditionDetailReq, DescribeTtsAuditionDetailRes>('DescribeTtsAuditionDetail', data);
  return res;
};
